/**
 * Copyright 2018 The Subscribe with Google Authors. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS-IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// NOTE: Please don't edit this file directly!
//   This guide describes how to change i18n strings in Swgjs: go/swg-showcase-i18n

export const I18N_STRINGS = {
  'SHOWCASE_REGWALL_TITLE': {
    'bg': 'Получавайте повече с Google',
    'bn': 'Google-এ আরও অনেক কিছুর সুবিধা পান',
    'cs': 'Získejte s&nbsp;Googlem víc',
    'da': 'Få adgang til mere med Google',
    'de': 'Immer gut informiert mit Google',
    'el': 'Αποκτήστε περισσότερα με την Google',
    'en': 'Get more with Google',
    'es': 'Disfruta de más artículos con Google',
    'es-419': 'Disfruta más artículos con Google',
    'fr': 'Plus de contenus avec Google',
    'fr-ca': 'Aller plus loin avec Google',
    'hi': 'Google की मदद से ज़्यादा मुफ़्त लेख पाएं',
    'it': 'Con Google puoi avere di più',
    'ja': 'Google でもっと読もう',
    'kn': 'Google ನಿಂದ ಹೆಚ್ಚಿನ ಪ್ರಯೋಜನ ಪಡೆಯಿರಿ',
    'lt': 'Gaukite daugiau su „Google“',
    'lv': 'Iegūstiet vairāk ar Google',
    'ml': 'Google ഉപയോഗിച്ച് കൂടുതൽ പ്രയോജനങ്ങൾ നേടൂ',
    'mr': 'Google वापरून बरेच काही मिळवा',
    'nl': 'Krijg meer met Google',
    'pl': 'Z&nbsp;Google dostajesz więcej',
    'pt-br': 'Veja mais com o Google',
    'pt-pt': 'Obtenha mais com a Google',
    'ro': 'Mai multe beneficii cu Google',
    'sk': 'Získajte viac s&nbsp;Googlom',
    'sl': 'Izkoristite več z Googlom',
    'sv': 'Få mer med Google',
    'ta': 'Google மூலம் மேலும் பல கட்டுரைகளைப் படியுங்கள்',
    'te': 'Googleతో మరిన్ని ప్రయోజనాలను పొందండి',
  },
  'SHOWCASE_REGWALL_DESCRIPTION': {
    'bg': 'За съдържанието обикновено се изисква плащане, но Google ви дава достъп без парично заплащане до тази и други статии, когато се регистрирате за {PUBLICATION} с профила си в Google.',
    'bn': 'এই কন্টেন্ট অ্যাক্সেস করার জন্য সাধারণত পেমেন্ট করতে হয় কিন্তু Google আপনাকে এই নিবন্ধ ফ্রিতে অ্যাক্সেস করতে এবং সেইসাথে অনেক কিছু পেতে সাহায্য করছে। এই সুবিধা পাওয়ার জন্য Google অ্যাকাউন্ট ব্যবহার করে আপনাকে {PUBLICATION}-এ রেজিস্টার করতে হবে।',
    'cs': 'Tento obsah je obvykle zpoplatněn, ale pokud se do publikace {PUBLICATION} zaregistrujete pomocí účtu Google, získáte od Googlu přístup zdarma.',
    'da': 'Du skal normalt betale for at få adgang til dette indhold, men hvis du tilmelder dig {PUBLICATION} med din Google-konto, giver Google dig gratis adgang til artiklen og andet indhold.',
    'de': 'Dieser Inhalt ist normalerweise kostenpflichtig. Google gewährt dir jedoch kostenlos Zugriff auf diesen Artikel und andere Inhalte, wenn du dich mit deinem Google-Konto bei {PUBLICATION} registrierst.',
    'el': 'Συνήθως απαιτείται πληρωμή για αυτό το περιεχόμενο, αλλά η Google σας προσφέρει πρόσβαση χωρίς χρέωση σε αυτό το άρθρο και σε πολλά ακόμη, εάν εγγραφείτε στην έκδοση {PUBLICATION} με χρήση του Λογαριασμού σας Google.',
    'en': 'This content usually requires payment, but Google is giving you free access to this article and more when you register with {PUBLICATION} using your Google Account.',
    'es': 'Normalmente, es necesario pagar para ver este contenido, pero Google te ofrece acceso gratuito a este y otros artículos si te registras en {PUBLICATION} con tu cuenta de Google.',
    'es-419':
      'Normalmente, es necesario pagar para ver este contenido, pero Google te ofrece acceso gratuito a este y otros artículos si te registras en {PUBLICATION} con tu Cuenta&nbsp;de&nbsp;Google.',
    'fr': "Ce contenu est généralement payant, mais vous pouvez lire cet article et d'autres contenus gratuitement grâce à Google en vous inscrivant sur {PUBLICATION} avec votre compte Google.",
    'fr-ca':
      "Ce contenu est généralement payant, mais Google vous offre un accès gratuit à cet article et à d'autres si vous vous inscrivez à {PUBLICATION} à l'aide de votre compte Google.",
    'hi': 'आम तौर पर, इस कॉन्टेंट को पढ़ने के लिए पैसे चुकाने पड़ते हैं. हालांकि, Google की मदद से, इस लेख और अन्य कॉन्टेंट को मुफ़्त में ऐक्सेस किया जा सकता है. इसके लिए, आपको Google खाते का इस्तेमाल करके, {PUBLICATION} में रजिस्टर करना होगा.',
    'it': 'Generalmente questi contenuti sono a pagamento, ma Google ti offre accesso gratuito a questo e ad altri articoli se ti registri a {PUBLICATION} usando il tuo Account Google.',
    'ja': '通常、この記事をお読みいただくにはお支払いが必要ですが、お使いの Google アカウントで {PUBLICATION} に登録すると、この記事を無料でお読みいただけます。',
    'kn': 'ಸಾಮಾನ್ಯವಾಗಿ ಈ ವಿಷಯಕ್ಕಾಗಿ ಹಣ ಪಾವತಿಸಬೇಕಾಗುತ್ತದೆ, ಆದರೆ ನೀವು {PUBLICATION} ಗೆ ನಿಮ್ಮ Google ಖಾತೆಯ ಮೂಲಕ ನೋಂದಾಯಿಸಿಕೊಂಡಾಗ Google ಈ ಲೇಖನ ಮತ್ತು ಇನ್ನಷ್ಟು ವಿಷಯಗಳಿಗೆ ನಿಮಗೆ ಉಚಿತವಾದ ಪ್ರವೇಶವನ್ನು ನೀಡುತ್ತದೆ.',
    'lt': 'Šis turinys paprastai yra mokamas, tačiau „Google“ suteikia jums prieigą prie šio straipsnio ir kt. be papildomo mokesčio, kai užsiregistruojate „{PUBLICATION}“ naudodami savo „Google“ paskyrą.',
    'lv': 'Parasti šis ir maksas saturs, taču Google piešķirs jums bezmaksas piekļuvi šim un citiem rakstiem, ja reģistrēsieties izdevumam {PUBLICATION} ar savu Google kontu.',
    'ml': 'സാധാരണ ഈ ഉള്ളടക്കത്തിന് പണം നൽകേണ്ടതുണ്ട്, എന്നാൽ Google അക്കൗണ്ട് ഉപയോഗിച്ച് {PUBLICATION} എന്നതിൽ രജിസ്‌റ്റർ ചെയ്യുമ്പോൾ, ഈ ലേഖനത്തിലേക്കും മറ്റും Google നിങ്ങൾക്ക് സൗജന്യ ആക്‌സസ് നൽകുന്നു.',
    'mr': 'या आशयासाठी सामान्यतः पेमेंट आवश्यक असते पण तुम्ही तुमचे Google खाते वापरून {PUBLICATION} मध्ये नोंदणी करता तेव्हा, Google तुम्हाला या लेखाचा आणि आणखी बऱ्याच आशयाचा विनामूल्य ॲक्सेस देते.',
    'nl': 'Voor deze content moet je eigenlijk betalen. Maar Google geeft je kosteloos toegang tot dit artikel en andere content als je je registreert bij {PUBLICATION} via je Google-account.',
    'pl': 'Te treści zazwyczaj wymagają opłaty, ale dzięki Google możesz bezpłatnie przeczytać ten artykuł i&nbsp;korzystać z&nbsp;innych materiałów po zarejestrowaniu się w&nbsp;publikacji {PUBLICATION} za pomocą konta Google.',
    'pt-br':
      'Normalmente, é preciso pagar por este conteúdo. Porém, basta você se registrar na publicação {PUBLICATION} usando sua Conta do Google para ter acesso a esta matéria e muito mais sem custos financeiros.',
    'pt-pt':
      'Geralmente, este conteúdo requer um pagamento, mas a Google concede-lhe acesso gratuito a este artigo e muito mais ao registar-se na publicação {PUBLICATION} com a sua Conta Google.',
    'ro': 'Acest conținut este de obicei cu plată, dar Google vă oferă acces fără costuri la acest articol și la altele când vă înregistrați la {PUBLICATION} folosind Contul Google.',
    'sk': 'Tento obsah je obvykle platený, ale ak sa do publikácie {PUBLICATION} zaregistrujete účtom Google, získate od Googlu bezplatný prístup k&nbsp;tomuto článku a&nbsp;ďalšie výhody.',
    'sl': 'Za to vsebino je običajno zahtevano plačilo, vendar vam Google omogoča dostop do tega članka in drugega brez stroškov, če se z računom Google registrirate pri publikaciji {PUBLICATION}.',
    'sv': 'Det krävs vanligtvis betalning för det här innehållet, men Google ger dig gratis åtkomst till artikeln och annat innehåll när du registrerar dig hos {PUBLICATION} med ditt Google-konto.',
    'ta': 'வழக்கமாக இந்த உள்ளடக்கத்தை வாசிக்க கட்டணம் செலுத்த வேண்டியிருக்கும். ஆனால் {PUBLICATION} இல் உங்கள் Google கணக்கைப் பயன்படுத்திப் பதிவுசெய்யும்போது இந்தக் கட்டுரைக்கும் மேலும் பலவற்றுக்கும் Google இலவச அணுகலை வழங்குகிறது.',
    'te': 'ఈ కంటెంట్‌కు మీరు సాధారణంగా పేమెంట్ చేయాల్సి ఉంటుంది, కానీ మీరు Google ఖాతాను ఉపయోగించి {PUBLICATION}తో రిజిస్టర్ చేసుకున్నప్పుడు, ఈ వార్తా కథనానికి ఇంకా మరెన్నో వాటికి Google, ఉచిత యాక్సెస్‌ను ఇస్తుంది.',
  },
  'SHOWCASE_REGWALL_PUBLISHER_SIGN_IN_BUTTON': {
    'bg': 'Вече сте се регистрирали? Вход',
    'bn': 'আগে থেকেই রেজিস্টার করেছেন? সাইন-ইন করুন',
    'cs': 'Jste už zaregistrováni? Přihlásit se',
    'da': 'Er du allerede tilmeldt? Log ind',
    'de': 'Bereits registriert? Anmelden',
    'el': 'Έχετε εγγραφεί ήδη; Σύνδεση',
    'en': 'Already registered? Sign in',
    'es': '¿Ya te has registrado? Iniciar sesión',
    'es-419': '¿Ya te registraste? Accede',
    'fr': 'Déjà inscrit&nbsp;? Connectez-vous',
    'fr-ca': 'Déjà inscrit? Se connecter',
    'hi': 'पहले से रजिस्टर किया हुआ है? साइन इन करें',
    'it': 'Hai già effettuato la registrazione? Accedi',
    'ja': '登録済みの方: ログイン',
    'kn': 'ಈಗಾಗಲೇ ನೋಂದಾಯಿಸಲಾಗಿದೆಯೇ? ಸೈನ್ ಇನ್',
    'lt': 'Jau užsiregistravote? Prisijungti',
    'lv': 'Vai esat jau reģistrējies? Pierakstīties',
    'ml': 'മുമ്പേ രജിസ്റ്റർ ചെയ്തിട്ടുണ്ടോ? സൈൻ ഇൻ ചെയ്യുക',
    'mr': 'आधीपासून नोंदणी केली आहे का? साइन इन करा',
    'nl': 'Al geregistreerd? Inloggen',
    'pl': 'Jesteś już zarejestrowanym użytkownikiem? Zaloguj się',
    'pt-br': 'Já se inscreveu? Fazer login',
    'pt-pt': 'Já fez o seu registo? Inicie sessão',
    'ro': 'V-ați înregistrat deja? Conectați-vă',
    'sk': 'Už máte zaregistrovaný účet? Prihlásiť sa',
    'sl': 'Ste že registrirani? Prijavite se.',
    'sv': 'Har du redan registrerat dig? Logga in',
    'ta': 'ஏற்கெனவே பதிவுசெய்துள்ளீர்களா? உள்நுழைக',
    'te': 'ఇప్పటికే రిజిస్టర్ చేయబడి ఉందా? సైన్ ఇన్ చేయండి',
  },
  'SHOWCASE_REGWALL_GOOGLE_SIGN_IN_BUTTON': {
    'bg': 'Вход с Google',
    'bn': 'Google দিয়ে সাইন-ইন করুন',
    'cs': 'Přihlásit se přes Google',
    'da': 'Log ind med Google',
    'de': 'Über Google anmelden',
    'el': 'Σύνδεση μέσω Google',
    'en': 'Sign in with Google',
    'es': 'Iniciar sesión con Google',
    'es-419': 'Acceder con Google',
    'fr': 'Se connecter avec Google',
    'fr-ca': 'Se connecter avec Google',
    'hi': 'Google से साइन इन करें',
    'it': 'Accedi con Google',
    'ja': 'Google でログイン',
    'kn': 'Google ಖಾತೆ ಬಳಸಿಕೊಂಡು ಸೈನ್ ಇನ್ ಮಾಡಿ',
    'lt': 'Prisijunkite su „Google“',
    'lv': 'Pierakstīties, izmantojot Google',
    'ml': 'Google ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യുക',
    'mr': 'Google वापरून साइन इन करा',
    'nl': 'Inloggen met Google',
    'pl': 'Zaloguj się przez Google',
    'pt-br': 'Fazer login com o Google',
    'pt-pt': 'Iniciar sessão com o Google',
    'ro': 'Conectează-te cu Google',
    'sk': 'Prihlásiť sa účtom Google',
    'sl': 'Prijavite se z Googlom',
    'sv': 'Logga in med Google',
    'ta': 'Google மூலம் உள்நுழைக',
    'te': 'Googleతో సైన్ ఇన్ చేయండి',
  },
  'SHOWCASE_REGWALL_CASL': {
    'bg': 'Прегледайте {LINK_START}Общите условия за канадското законодателство за борба със спама{LINK_END} на {PUBLICATION}',
    'bn': '{PUBLICATION}-এর {LINK_START}CASL শর্ত{LINK_END} পর্যালোচনা করুন',
    'cs': 'Prostudujte si {LINK_START}podmínky CASL{LINK_END} publikace {PUBLICATION}',
    'da': 'Gennemgå {LINK_START}CASL-vilkårene{LINK_END} (Canadian Anti-Spam Legislation, canadisk lovgivning vedrørende antispam) for {PUBLICATION}',
    'de': '{LINK_START}CASL-Bedingungen{LINK_END} von {PUBLICATION} ansehen',
    'el': 'Ελέγξτε αν τηρούνται στη δημοσίευση {PUBLICATION} οι {LINK_START}Όροι CASL{LINK_END}',
    'en': "Review {PUBLICATION}'s {LINK_START}CASL terms{LINK_END}",
    'es': 'Consulta los {LINK_START}términos de la CASL{LINK_END} de {PUBLICATION}',
    'es-419':
      'Consulta las {LINK_START}condiciones de CASL{LINK_END} de {PUBLICATION}',
    'fr': "Consultez les {LINK_START}Conditions d'utilisation LCAP (Loi canadienne anti-pourriel){LINK_END} de {PUBLICATION}",
    'fr-ca':
      "Consulter les {LINK_START}conditions d'utilisation relatives à la Loi canadienne antipourriel (LCAP){LINK_END} de la publication {PUBLICATION}",
    'hi': '{PUBLICATION} की {LINK_START}सीएएसएल (कैनेडियन एंटी-स्पैम लेजिस्लेशन) से जुड़ी शर्तों{LINK_END} के बारे में पढ़ें',
    'it': 'Rileggi i {LINK_START}termini della legge CASL{LINK_END} di {PUBLICATION}',
    'ja': '{PUBLICATION} の {LINK_START}CASL 規約{LINK_END}を見る',
    'kn': '{PUBLICATION} ನ {LINK_START}CASL ನಿಯಮಗಳು{LINK_END} ಅನ್ನು ಪರಿಶೀಲಿಸಿ',
    'lt': 'Peržiūrėkite „{PUBLICATION}“ {LINK_START}CASL sąlygas{LINK_END}',
    'lv': 'Pārskatīt {PUBLICATION} {LINK_START}CASL noteikumus{LINK_END}',
    'ml': '{PUBLICATION} എന്നതിന്റെ {LINK_START}CASL നിബന്ധനകൾ{LINK_END} അവലോകനം ചെയ്യുക',
    'mr': '{PUBLICATION} च्या {LINK_START}CASL अटी{LINK_END} यांचे पुनरावलोकन करा',
    'nl': 'Bekijk de {LINK_START}CASL-voorwaarden{LINK_END} van {PUBLICATION}',
    'pl': 'Zapoznaj się z&nbsp;{LINK_START}warunkami CASL{LINK_END} publikacji {PUBLICATION}',
    'pt-br':
      'Confira os {LINK_START}termos da CASL{LINK_END} da publicação {PUBLICATION}',
    'pt-pt':
      'Analise os {LINK_START}termos da CASL{LINK_END} da publicação {PUBLICATION}',
    'ro': 'Examinați {LINK_START}Termenii CASL{LINK_END} ai {PUBLICATION}',
    'sk': 'Prečítajte si {LINK_START}podmienky CASL{LINK_END} publikácie {PUBLICATION}',
    'sl': 'Preglejte {LINK_START}pogoje CASL{LINK_END} za publikacijo {PUBLICATION}',
    'sv': 'Läs {LINK_START}villkoren i lagstiftningen CASL{LINK_END} för {PUBLICATION}',
    'ta': '{PUBLICATION} இன் {LINK_START}CASL விதிமுறைகளைப்{LINK_END} பாருங்கள்',
    'te': '{PUBLICATION}‌కు సంబంధించిన {LINK_START}CASL నియమాల{LINK_END}ను రివ్యూ చేయండి',
  },
};
